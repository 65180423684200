a:hover {
 cursor:pointer;
}

.container-cookies-consent {
    align-items: baseline;
    background: rgb(53, 53, 53) none repeat scroll 0% 0%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;
}

.content-cookies-consent {
	flex: .3 0 300px;
	margin: 15px;
}

.button-cookies-consent {
    flex: 1;
    display: block;
    margin-right: 120px;
    margin-left: 20px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.bootstrap-switch-primary{
    background: #ec640b !important;
}

.bootstrap-switch .bootstrap-switch-label {
    background: #ffffff !important;
}

.modal-content .modal-body {
    padding-top: 0px !important;
}

.modal-content .modal-header button {
    top: 10px !important;
}

.modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.modal-content .modal-header {
    padding-top: 10px !important;
}

.title {
    padding-top: 0px !important;
}

.modal-content .modal-header .title {
    margin-top: 0px !important;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.slider-oferta:hover {
 cursor:pointer;
}

.page-header::after, .page-header::before {
    position: absolute;
    z-index: 0;
    width: 0%;
    height: 0%;
    display: block;
    left: 0;
    top: 0;
    content: none !important;
}
.page-header::before {
    background-color: none !important;
}

.mail-to {
    align-items: center;
    display: flex;
    justify-content: center;
}

.progressbar-download {
  margin-bottom: 32px;
}

.icon-pad-right {
    margin-right: 8px;
}

.btn-catalogo {
    margin: 10px 5px !important;
}

.card-background-catalogo {
    background-position: center center;
    background-size: contain;
    text-align: center;
    min-height: 262px;
    margin-bottom: 0px;
    min-width: 255px;
    background-repeat: no-repeat;
}

.card-background-catalogo:hover {
 cursor:pointer;
}

.card-raised-catalogo {
    box-shadow: none;
}

.pagination .page-item .page-link {
    max-width: 40px !important;
}

.arrow-margin-left-init-modal {
    left: 24px;
    position: absolute;
}

.arrow-margin-left-modal {
    left: 60px;
    position: absolute;
}

.arrow-margin-right-modal {
    right: 60px;
    position: absolute;
}

.arrow-margin-right-end-modal {
    right: 24px;
    position: absolute;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.info-servicios {
    text-align: center !important;
}

.icon-right {
    float: right !important;
    margin-top: 23px;
    margin-right: 10px;
}

.features-6 .info:first-child {
    padding-top: 0px !important;
}

.features-6 .phone-container {
    max-width: none !important;
    margin: 60px auto 0;
    display: inline-block;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.phone-container img {
    width: 100%;
    height: 100%;
}

.about-valores {
 list-style-Type: none;
 padding: 0 0 4px 23px;
 background: url(https://www.computerhope.com/cdn/arrow.png) no-repeat left center;
}

.info-about {
    margin: 0 auto;
    padding: 70px 0 30px;
    text-align: center;    
}

.page-header-about{
    max-height: 300px !important;
    min-height: 300px !important;
    padding: 0;
    color: #eeeeee;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}


.page-header-about::after, .page-header-about::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}
.page-header-about::before {
    background-color: rgba(0, 0, 0, 0.3);
}

.page-header-about .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.page-header-about .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}

.aboutus-imgheader {
    margin: -90px 0px 90px 0;
    box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.48);
    border-radius: 0.1875rem;
    background-color: #eeeeee;
    min-height: 400px;
}

.navbar-container {
    min-height: 40px;
}

.wrapper-empty {
    min-height: 600px
}

.section-image::after {
    background-color: rgba(0, 0, 0, 0.44);
}

.header-margen {
    margin-top: 170px !important;
}

.whastapp-btn{
    right: 0px;
    position: fixed;
    bottom: 0px;
    z-index: 1030;
}    


.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn), .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus, .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover, .navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
    background-color: rgb(0, 0, 0);
    
}

.nav-item {
    margin-right: 0px !important;
}

.nav-item:hover {
    cursor:pointer !important;
}

.section-footers {
    padding: 0 !important;
}

.slide-header {
    /* margin-top: 100px; */
}

.carousel-inner {
    /* max-height: 500px !important; */
}

.carousel-item {
    /* max-height: 500px !important; */
}

.page-header {
    max-height: 500px !important;
    min-height: 500px !important;  
    z-index: 2 !important;
}

.navbar-expand-lg {
    flex-flow: row wrap;
    justify-content: flex-start;
}

.navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
    background: #fff !important;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: #fff !important;
    font-size: 1.2em !important;
}

.navbar-collapse::before {
    background: #ec640b !important;
    background: linear-gradient(#ec640b, #990606 80%) !important;
}

.navbar-container{
    background-color: #ec640b;
    max-width: 100%;
}

.navbar {
    padding: 0px !important;
    min-height: 53px;
    margin-bottom: 0px !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.94);

    a{
        vertical-align: middle;

        &:not(.btn):not(.dropdown-item){
            color: $white-color;
        }
    }

    p{
        display: inline-block;
        margin: 0;
        line-height: 21px;
        font-weight: inherit;
        font-size: inherit;
    }

    .navbar-nav{
        &.navbar-logo{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 49px;
            top: -4px;
        }

        .nav-link.btn{
            padding: $padding-btn-vertical $padding-btn-horizontal;

            &.btn-lg{
                padding: $padding-large-vertical $padding-large-horizontal;
            }

            &.btn-sm{
                padding: $padding-small-vertical $padding-small-horizontal;
            }
        }

        .nav-link:not(.btn){
            text-transform: uppercase;
            font-size: $font-size-mini;
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-nav-link;

            i.fab + p,
            i.now-ui-icons + p{
                margin-left: 3px;
            }

            i.fab,
            i.now-ui-icons{
                font-size: 18px;
                position: relative;
                top: 3px;
                text-align: center;
                width: 21px;
            }

            i.now-ui-icons{
                top: 4px;
                font-size: 16px;
            }

            &.profile-photo{
                .profile-photo-small{
                    width: 27px;
                    height: 27px;
                }
            }

            &.disabled{
                opacity: .5;
                color: $white-color;
            }
        }

        .nav-item.active .nav-link:not(.btn),
        .nav-item .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):active{
            background-color: $opacity-2;
            border-radius: $border-radius-small;
        }
    }


    .navbar-brand{
        text-transform: uppercase;
        font-size: $font-size-small;
        padding-top: $padding-base-vertical;
        padding-bottom: $padding-base-vertical;
        line-height: $line-height-nav-link;
    }

    .navbar-toggler{
        width: 37px;
        height: 27px;
        outline: 0;
        cursor: pointer;

        &.navbar-toggler-left{
            position: relative;
            left: 0;
            padding-left: 0;
        }

        .navbar-toggler-bar.middle-bar{
          width: 17px;
          transition: width .2s linear;
        }

        &:hover{
            & .navbar-toggler-bar.middle-bar{
                width: 22px;
            }
        }
    }

   
}

